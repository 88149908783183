<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="companyUpdate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="First name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.first_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="First name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Last name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.last_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Last name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Type"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  vid="role_id"
                >
                  <v-select
                    v-model="form.role_id"
                    :options="roles"
                    :reduce="role_id => role_id.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Address Line 1*"
                label-for="address1"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 1"
                  vid="address1"
                  rules="max:255|required"
                >
                  <b-form-input
                    id="address1"
                    v-model="form.address1"
                    name="address1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address Line 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- address2 -->
              <b-form-group
                label="Address Line 2"
                label-for="address2"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 2"
                  vid="address2"
                  rules="max:255"
                >
                  <b-form-input
                    id="address2"
                    v-model="form.address2"
                    name="address2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address Line 2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Country -->
              <b-form-group
                label="Country*"
                label-for="country"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="country_id"
                  rules="max:255|required"
                >

                  <v-select
                    v-model="form.country_id"
                    :options="countries"
                    :reduce="country_id => country_id.id"
                    placeholder="Select country"
                    label="name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- region -->
              <b-form-group
                label="Region/State"
                label-for="region"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Region/State"
                  vid="state"
                  rules="max:255"
                >
                  <b-form-input
                    id="region"
                    v-model="form.state"
                    name="region"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Region/State"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- city -->
              <b-form-group
                label="Town/City"
                label-for="city"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Town/City"
                  vid="city"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="city"
                    v-model="form.city"
                    name="city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Town/City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- postcode -->
              <b-form-group
                label="Postcode/Zip Code*"
                label-for="postcode"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postcode/Zip Code"
                  vid="postcode"
                  rules="max:255|required"
                >
                  <b-form-input
                    id="postcode"
                    v-model="form.postcode"
                    name="postcode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Postcode/Zip Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Telephone 1"
                label-for="register-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  vid="phone_number1"
                  rules="numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-phone_number"
                    ref="phoneNumber1"
                    v-model="form.phone_number1"
                    class="form-control pl-0"
                    name="register-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="phoneISO1"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ errors[0] || phoneRequiredError }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Telephone 2"
                label-for="register-phone_number2"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone 2"
                  vid="phone_number2"
                  rules="numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-phone_number2"
                    ref="phoneNumber2"
                    v-model="form.phone_number2"
                    class="form-control pl-0"
                    name="register-phone_number2"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="phoneISO2"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="max:255|required|email"
                >
                  <b-form-input
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="isActive"
                  v-model="form.is_active"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  Active Status
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, max, integer, email, url,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'

const VueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
// eslint-disable-next-line no-shadow
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
    VueTelInput,
  },
  data() {
    return {
      loading: true,
      form: {
        first_name: null,
        last_name: null,
        country_id: null,
        address1: null,
        address2: null,
        city: null,
        postcode: null,
        state: null,
        phone_country_iso1: null,
        phone_country_code1: null,
        phone_number1: null,
        phone_country_iso2: null,
        phone_country_code2: null,
        phone_number2: null,
        email: null,
        is_active: 0,
      },
      id: null,
      required,
      max,
      integer,
      email,
      url,
      phoneISO1: null,
      phoneISO2: null,
      phoneRequiredError: '',
    }
  },
  computed: {
    ...mapState('userManagement', {
      roles: 'roles',
      countries: 'countries',
      defaultIso: 'defaultIso',
      item: 'item',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.phone_number1': function (val) {
      if (val) {
        this.phoneRequiredError = ''
      }
    },
  },
  async mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      await this.fetchModel(this.id)
      this.phoneISO1 = this.item.phone_country_iso1
      this.phoneISO2 = this.item.phone_country_iso2
      this.selectISO()
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('userManagement', [
      'getItem',
      'updateItem',
    ]),
    selectISO() {
      if (!this.phoneISO1) {
        setTimeout(() => {
          this.$refs.phoneNumber1.choose(this.defaultIso ? this.defaultIso : 'GB')
        }, 500)
      }
      if (!this.phoneISO2) {
        setTimeout(() => {
          this.$refs.phoneNumber2.choose(this.defaultIso ? this.defaultIso : 'GB')
        }, 500)
      }
    },
    onSearchParentCompany(query, id) {
      if (!query) {
        return
      }
      this.fetchParentCompanyList({ query, id })
    },
    async fetchModel(id) {
      try {
        await this.getItem(id)
        if (this.$refs.phoneNumber) {
        // eslint-disable-next-line camelcase
          this.$refs.phoneNumber.choose(this.item.phone_country_iso ?? this.defaultIso)
        }
        this.form.first_name = this.item.first_name
        this.form.last_name = this.item.last_name
        this.form.address1 = this.item.addresses.address1
        this.form.address2 = this.item.addresses.address2
        this.form.postcode = this.item.addresses.postcode
        this.form.state = this.item.addresses.state
        this.form.city = this.item.addresses.city
        this.form.phone_country_iso1 = this.item.phone_country_iso1
        this.form.phone_country_code1 = this.item.phone_country_code1
        this.form.phone_number1 = this.item.phone_number1
        this.form.phone_country_iso2 = this.item.phone_country_iso2
        this.form.phone_country_code2 = this.item.phone_country_code2
        this.form.phone_number2 = this.item.phone_number2
        this.form.email = this.item.email
        this.form.name = this.item.name
        this.form.country_id = this.item.addresses.country ? this.item.addresses.country.id : null
        this.form.role_id = this.item.role ? this.item.role.id : null
        this.form.is_active = this.item.is_active ? 1 : 0
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async validationForm() {
      const valid = await this.$refs.companyUpdate.validate()
      if (!this.form.phone_number1) {
        this.phoneRequiredError = 'The Telephone field is required'
      }
      if (valid && this.form.phone_number1) {
        try {
          let payload = {
            ...this.form,
            ...{ phone_country_iso1: this.$refs.phoneNumber1.phoneObject.country.iso2 },
            ...{ phone_country_code1: this.$refs.phoneNumber1.phoneObject.country.dialCode },
          }
          if (this.form.phone_number2) {
            payload = {
              ...this.form,
              ...{ phone_country_iso2: this.$refs.phoneNumber2.phoneObject.country.iso2 },
              ...{ phone_country_code2: this.$refs.phoneNumber2.phoneObject.country.dialCode },
              ...{ phone_country_iso1: this.$refs.phoneNumber1.phoneObject.country.iso2 },
              ...{ phone_country_code1: this.$refs.phoneNumber1.phoneObject.country.dialCode },
            }
          } else {
            payload = {
              ...this.form,
              ...{ phone_country_iso2: null },
              ...{ phone_country_code2: null },
              ...{ phone_country_iso1: this.$refs.phoneNumber1.phoneObject.country.iso2 },
              ...{ phone_country_code1: this.$refs.phoneNumber1.phoneObject.country.dialCode },
            }
          }
          await this.updateItem({ id: this.id, payload })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'user-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.companyUpdate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
