var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"companyUpdate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"First name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"First name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"First name","autocomplete":"off"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Last name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Last name","autocomplete":"off"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Type","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Type","vid":"role_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.roles,"reduce":function (role_id) { return role_id.id; },"label":"name","searchable":false},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Address Line 1*","label-for":"address1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"address1","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address1","name":"address1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1"},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Address Line 2","label-for":"address2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"address2","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address2","name":"address2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2"},model:{value:(_vm.form.address2),callback:function ($$v) {_vm.$set(_vm.form, "address2", $$v)},expression:"form.address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Country*","label-for":"country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"country_id","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Region/State","label-for":"region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Region/State","vid":"state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"region","name":"region","state":errors.length > 0 ? false:null,"placeholder":"Region/State"},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Town/City","label-for":"city","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town/City","vid":"city","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"city","state":errors.length > 0 ? false:null,"placeholder":"Town/City"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Postcode/Zip Code*","label-for":"postcode","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Postcode/Zip Code","vid":"postcode","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","name":"postcode","state":errors.length > 0 ? false:null,"placeholder":"Postcode/Zip Code"},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telephone 1","label-for":"register-phone_number","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Telephone","vid":"phone_number1","rules":"numeric|min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{ref:"phoneNumber1",staticClass:"form-control pl-0",attrs:{"id":"register-phone_number","name":"register-phone_number","preferred-countries":['GB'],"auto-default-country":false,"auto-format":false,"default-country":_vm.phoneISO1,"dropdown-options":{
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showDialCodeInList: true
                  }},model:{value:(_vm.form.phone_number1),callback:function ($$v) {_vm.$set(_vm.form, "phone_number1", $$v)},expression:"form.phone_number1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.phoneRequiredError))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telephone 2","label-for":"register-phone_number2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Telephone 2","vid":"phone_number2","rules":"numeric|min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('vue-tel-input',{ref:"phoneNumber2",staticClass:"form-control pl-0",attrs:{"id":"register-phone_number2","name":"register-phone_number2","preferred-countries":['GB'],"auto-default-country":false,"auto-format":false,"default-country":_vm.phoneISO2,"dropdown-options":{
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showDialCodeInList: true
                  }},model:{value:(_vm.form.phone_number2),callback:function ($$v) {_vm.$set(_vm.form, "phone_number2", $$v)},expression:"form.phone_number2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"max:255|required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Email","autocomplete":"off"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isActive","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v(" Active Status ")])],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }